<template>
  <div class="page">
    <Head :headTitle="headTitle" />
    <div class="container">
      <div class="con-l">
        <div class="mod mod1">
          <div class="s-pub-tt flex">
            <span class="bt">网格数量</span>
          </div>
          <div class="chart" ref="chart1"></div>
        </div>
        <div class="mod mod2">
          <div class="s-pub-tt flex">
            <span class="bt">信访情况</span>
          </div>
          <ul class="mod2-list flex-1">
            <li>
              <div class="tit">接访领导</div>
              <div class="txt">
                <span>{{ leaders }}</span
                >人
              </div>
            </li>
            <li>
              <div class="tit">来访群众</div>
              <div class="txt">
                <span>{{ people }}</span
                >人
              </div>
            </li>
          </ul>
          <!-- <div class="chart flex-11" ref="chart1"></div> -->
        </div>
        <div class="mod mod3">
          <div class="s-pub-tt flex">
            <span class="bt">文明城市</span>
          </div>
          <ul class="mod3-list">
            <li>
              <img src="https://home/ResImg/zh_01.webp" alt="" />
            </li>
            <li>
              <img src="https://home/ResImg/zh_02.jpeg" alt="" />
            </li>
            <li>
              <img src="https://home/ResImg/zh_03.png" alt="" />
            </li>
            <li>
              <img src="https://home/ResImg/zh_04.webp" alt="" />
            </li>
            <li>
              <img src="https://home/ResImg/zh_05.webp" alt="" />
            </li>
            <li>
              <img src="https://home/ResImg/zh_06.jpg" alt="" />
            </li>
            <li>
              <img src="https://home/ResImg/zh_07.jpeg" alt="" />
            </li>
            <li>
              <img src="https://home/ResImg/zh_08.webp" alt="" />
            </li>
            <li>
              <img src="https://home/ResImg/zh_09.webp" alt="" />
            </li>
          </ul>
          <!-- <div class="chart chart4 flex-11" ref="chart4"></div> -->
        </div>
      </div>
      <div class="con-c flex flex-v relative">
        <div class="cen-map">
          <img src="https://home/ResImg/gldt-wgy.gif" class="pic" alt="" />

          <!-- <div
            class="item item1"
            v-for="(item, index) in gridList"
            :style="indexNum == index ? 'opacity:1' : ''"
            :key="index"
          >
            <div class="top">
              <div class="hdpic">
                <div class="title">{{ item.title }}网格人员</div>
              </div>
              <div class="top-container">
                <div
                  class="info"
                  v-for="(itVal, idx) in item.townDrid"
                  :key="idx"
                >
                  <div class="flex flex-align-center">
                    <h6 class="name">{{ itVal.name }}</h6>
                  </div>
                  <div class="tel">{{ itVal.tel }}</div>
                </div>
                <div class="line"></div>
              </div>
              <div class="line_center"></div>
              <div class="bottom_center"></div>
            </div>
          </div> -->
        </div>
      </div>
      <div class="con-r flex flex-v">
        <div class="mod mod4">
          <div class="s-pub-tt flex">
            <span class="bt">综合管理</span>
          </div>

          <ul class="mod4-list flex-1">
            <!-- <li v-for="(item, index) in imgList" :key="index">
              <div class="tit">{{ item.title }}</div>
              <div class="txt">
                <img
                  src="https://media.shouxinfund.com/jieyang/city_005.jpg"
                  alt=""
                />
              </div>
            </li> -->
            <li>
              <div class="tit">消防队救援</div>
              <div class="txt">
                <img src="https://home/ResImg/gl_01.png" alt="" />
              </div>
            </li>
            <li>
              <div class="tit">消防队救援</div>
              <div class="txt">
                <img src="https://home/ResImg/gl_02.png" alt="" />
              </div>
            </li>
            <li>
              <div class="tit">消防队救援</div>
              <div class="txt">
                <img src="https://home/ResImg/gl_03.png" alt="" />
              </div>
            </li>
            <li>
              <div class="tit">交警指挥</div>
              <div class="txt">
                <img src="https://home/ResImg/gl_04.png" alt="" />
              </div>
            </li>
            <li>
              <div class="tit">交警指挥</div>
              <div class="txt">
                <img src="https://home/ResImg/gl_05.png" alt="" />
              </div>
            </li>
            <li>
              <div class="tit">志愿者服务</div>
              <div class="txt">
                <img src="https://home/ResImg/gl_06.png" alt="" />
              </div>
            </li>
            <li>
              <div class="tit">志愿者服务</div>
              <div class="txt">
                <img src="https://home/ResImg/gl_07.png" alt="" />
              </div>
            </li>
            <li>
              <div class="tit">中支队执法</div>
              <div class="txt">
                <img src="https://home/ResImg/gl_08.webp" alt="" />
              </div>
            </li>
          </ul>
          <!-- <div class="chart flex-11" ref="chart3"></div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Head from "@/components/head_sm.vue";

// import autoResize from "@jiaminghi/data-view/lib/mixin/autoResize";
let Echarts = require("echarts/lib/echarts");
import { loopShowTooltip } from "../assets/js/tooltip-auto-show-vue.js";

export default {
  data() {
    return {
      headTitle: "智慧城市综合管理",
      things: "", //事项
      count: "", //数量
      beautyCountry: {}, //美丽乡村
      leaders: "", //接访领导
      people: "", //来访群众
      mySwiper1: [],
      sqscList: [],
      centerList: [], //文明城市
      imgList: [], //文明城市图片列表页
      className: [], //动态添加类名
      gridList: [], //网格员
      chart1: null,
      tootipTimer1: "",
      indexNum: 0,
    };
  },
  components: {
    Head,
  },
  computed: {
    scrollOption() {
      return {
        step: 0.3, // 数值越大速度滚动越快
        // limitMoveNum: 10, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        // openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  created() {
    // 获取综合管理平台数据
    clearTimeout();
    this.getZongheInfo();
  },
  mounted() {
    setTimeout(() => {
      this.init1();
    }, 1000);
    // setInterval(() => {
    //   // this.indexNum = Math.floor(Math.random() * (this.tipsList.length - 1));
    //   if (this.indexNum >= 12) {
    //     this.indexNum = 0;
    //     return;
    //   } else {
    //     this.indexNum++;
    //     console.log("this.indexNum===>", this.indexNum);
    //   }
    // }, 3000);
    window.addEventListener("resize", () => {
      this.chart1.resize();
    });
  },

  methods: {
    init1() {
      this.chart1 = Echarts.init(this.$refs.chart1);
      let option = {
        title: {
          text: "单位:个",
          top: 10,
          right: 10,
          textStyle: {
            fontSize: 12,
            color: "#fff",
          },
        },
        legend: {
          data: ["一级网格", "二级网格"],
          textStyle: {
            color: "#fff",
          },
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(0,0,0,.65)",
          textStyle: {
            fontSize: nowSize(12),
            color: "#fff",
          },
          extraCssText: "border:1px solid #3FF5FF",
          axisPointer: {
            crossStyle: {
              color: "#fff",
            },
          },
        },
        toolbox: {
          show: true,
        },
        grid: {
          left: nowSize(10),
          right: nowSize(10),
          bottom: nowSize(5),
          top: nowSize(20),
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            //boundaryGap: false,
            data: ["2018年", "2019年", "2020年", "2021年", "2022年"],
            // data:chart6Year,
            axisPointer: {
              type: "",
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#fff",
                fontSize: nowSize(20),
              },
              //rotate: '0',
              interval: 0,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                // color: "#fff54f", //更改坐标轴颜色
              },
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: ["#edf2f8"],
                width: 1,
                type: "dashed",
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "",
            position: "left",
            min: 0,
            max: 500,
            interval: 100,
            nameTextStyle: {
              color: "#fff",
              fontSize: nowSize(16),
              padding: [0, 0, 0, -20],
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: "#fff", //更改坐标轴文字颜色
                fontSize: nowSize(20), //更改坐标轴文字大小
              },
              //formatter: '{value}%'
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: "#fff54f", //更改坐标轴颜色
              },
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: ["#fff54f"],
                width: 1,
                type: "solid",
              },
            },
          },
        ],
        series: [
          {
            name: "一级网格",
            type: "bar",
            stack: "Total",
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: "#fff",
                fontSize: nowSize(16),
              },
            },
            showBackground: false,
            barWidth: nowSize(30),
            data: [292, 219, 285, 256, 123],
            itemStyle: {
              normal: {
                barBorderRadius: 0,
                color: new Echarts.graphic.LinearGradient(0, 1, 0, 0, [
                  {
                    offset: 0,
                    color: "rgb(86,230,215)",
                  },
                  {
                    offset: 1,
                    color: "rgb(51,120,255)",
                  },
                ]),
              },
            },
          },
          {
            name: "二级网格",
            type: "bar",
            data: [212, 219, 214, 200, 254],
            showBackground: false,
            barWidth: nowSize(30),
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: "#fff",
                fontSize: nowSize(16),
              },
            },
          },
        ],
        // series:[{type:'bar'},{type:'bar'}]
      };
      this.chart1.setOption(option, true);

      this.tootipTimer1 && this.tootipTimer1.clearLoop();
      this.tootipTimer1 = 0;
      this.tootipTimer1 = loopShowTooltip(this.chart1, option, {});
    },

    // init4() {
    //   axios.get("/10zhgl.json").then(
    //     (res) => {
    //       console.log("请求成功", res.data);
    //       this.chart4 = Echarts.init(this.$refs.chart4);
    //       let chartData = res.data.fpin;
    //       const sum = chartData.reduce((per, cur) => per + cur.value, 0);
    //       let option = {
    //         title: {
    //           text: `{a|累计扶贫资金}\n\n{b|${sum}万元}`,
    //           top: "middle",
    //           textStyle: {
    //             rich: {
    //               a: {
    //                 fontSize: nowSize(38),
    //                 padding: [nowSize(-20), 0, 0, nowSize(160)],
    //                 color: "#fff",
    //                 lineHeight: 1,
    //                 fontFamily: "PangMenZhengDao",
    //               },
    //               b: {
    //                 fontSize: nowSize(30),
    //                 padding: [0, 0, 0, nowSize(165)],
    //                 color: "#45F5FF",
    //                 lineHeight: 1,
    //                 fontFamily: "PangMenZhengDao",
    //               },
    //             },
    //           },
    //         },
    //         tooltip: {
    //           trigger: "item",
    //           backgroundColor: "rgba(0,0,0,.9)",
    //           textStyle: {
    //             fontSize: nowSize(12),
    //             color: "#fff",
    //           },
    //           extraCssText: "border:1px solid #3FF5FF",
    //           axisPointer: {
    //             crossStyle: {
    //               color: "#fff",
    //             },
    //           },
    //           position(point, params, dom, rect, size) {},
    //         },
    //         grid: {
    //           left: "0",
    //           right: "0",
    //           bottom: "0",
    //           top: "0",
    //           containLabel: true,
    //         },
    //         legend: {
    //           x: "right",
    //           y: "center",
    //           //   top:"bottom",
    //           orient: "scroll",
    //           icon: "rect",
    //           itemWidth: nowSize(24),
    //           itemHeight: nowSize(24),
    //           textStyle: {
    //             color: "#fff",
    //             fontSize: nowSize(24),
    //             lineHeight: nowSize(40),
    //           },
    //           formatter: (name) => {
    //             // console.log(name);
    //             // console.log(chartData);
    //             const item = chartData.find((i) => {
    //               return i.name === name;
    //             });
    //             const p = ((item.value / sum) * 100).toFixed(0);
    //             const value = item.value;
    //             return (
    //               "{name|" +
    //               name +
    //               "}" +
    //               "{value|" +
    //               value +
    //               "元}" +
    //               "{percent|" +
    //               p +
    //               "%}"
    //             );
    //           },
    //           textStyle: {
    //             rich: {
    //               name: {
    //                 fontSize: nowSize(24),
    //                 width: nowSize(230),
    //                 height: nowSize(30),
    //                 padding: [0, 0, 0, 5],
    //                 color: "#fff",
    //               },
    //               value: {
    //                 fontSize: nowSize(24),
    //                 width: nowSize(110),
    //                 height: nowSize(30),
    //                 padding: [0, 0, 0, 5],
    //                 color: "#ffc71a",
    //               },
    //               percent: {
    //                 fontSize: nowSize(24),
    //                 width: nowSize(110),
    //                 height: nowSize(30),
    //                 padding: [0, 0, 0, 15],
    //                 color: "#03b581",
    //               },
    //             },
    //           },
    //         },
    //         color: [
    //           "#00b6ff",
    //           "#03b581",
    //           "#ffc71a",
    //           "#fe4335",
    //           "#0060db",
    //           "#79c0de",
    //         ],
    //         series: [
    //           {
    //             name: "",
    //             type: "pie",
    //             radius: ["50%", "70%"],
    //             center: ["25%", "48%"],
    //             avoidLabelOverlap: false,
    //             hoverAnimation: true,
    //             label: {
    //               normal: {
    //                 show: false,
    //               },
    //             },
    //             itemStyle: {
    //               borderRadius: 0,
    //               borderColor: "#000",
    //               borderWidth: 3,
    //             },
    //             labelLine: {
    //               show: false,
    //             },
    //             data: chartData,
    //           },
    //           {
    //             type: "pie",
    //             name: "内层细圆环",
    //             radius: ["42%", "44%"],
    //             center: ["25%", "48%"],
    //             hoverAnimation: false,
    //             clockWise: true,
    //             itemStyle: {
    //               normal: {
    //                 color: "#178ac9",
    //               },
    //             },
    //             tooltip: {
    //               show: false,
    //             },
    //             label: {
    //               show: false,
    //             },
    //             data: [1],
    //           },
    //         ],
    //       };
    //       this.chart4.setOption(option, true);

    //       this.tootipTimer4 && this.tootipTimer4.clearLoop();
    //       this.tootipTimer4 = 0;
    //       this.tootipTimer4 = loopShowTooltip(this.chart4, option, {});
    //     },
    //     (err) => {
    //       console.log("请求失败", err.message);
    //     }
    //   );
    // },

    // 获取综合管理平台数据
    getZongheInfo() {
      console.log(this);
      axios
        .get("/10zhgl.json", {
          params: {
            // timestamp:new Date().getTime()
            random: Math.random(),
          },
        })
        .then(
          (res) => {
            console.log("请求成功了", res.data);
            console.log(res.data.xinfang.lingdao);
            this.headTitle = res.data.bigTitle;
            this.beautyCountry = res.data.beautyCountry;
            // this.things = this.beautyCountry.head1;
            // this.count = this.beautyCountry.head2;
            // this.sqscList = this.beautyCountry.countryList;
            this.leaders = res.data.xinfang.lingdao;
            console.log("this.leaders", this.leaders);
            this.people = res.data.xinfang.qunzhong;
            this.centerList = res.data.centerList;
            this.imgList = res.data.imgList;
            this.gridList = res.data.gridList;
            console.log("imgList====>", this.imgList);
            console.log("centerList====>", this.centerList);
            console.log("gridList====>", this.gridList);
            console.log("gridList====>", this.gridList[0].townDrid);
          },
          (err) => {
            console.log("请求失败了", err.message);
          }
        );
    },
  },
};
</script>

<style scoped>
.page {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  background: url(../assets/images/map_xc.jpg) no-repeat center;
  /* background: url(https://home/ResImg/xh_map1.jpg) no-repeat center; */
  background-size: cover;
  background-color: #0a224b;
}

.mod {
  margin-bottom: 2%;
  /* height: calc(33.3% - 20px); */
  display: flex;
  flex-direction: column;
}
.mod:last-child {
  bottom: 0;
}

.mod1 {
  height: 34%;
}
.mod1 .chart {
  height: calc(90% - 40px);
  flex: 1;
}
/* .mod1 .chart {
  height: calc(100% - 60px);
} */
.mod2 {
  height: 15%;
}
.mod3 {
  height: 45%;
}
.mod4 {
  height: 100%;
}
.mod4 .swiper-container {
  width: 50%;
  height: 80%;
}
.mod2-list {
  margin-top: 5%;
  margin-bottom: 5%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-gap: 30px;
}
.mod2-list li {
  background-color: #08172e;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;
  font-size: 32px;
  color: #fff;
}
.mod2-list .tit {
  font-size: inherit;
}
.mod2-list .txt span {
  font-size: 40px;
  color: #45f5ff;
  font-family: "simpds";
  padding-right: 8px;
}
.mod2-list .txt i {
  font-family: "simpds";
}

.mod3 .mod3-list {
  width: 100%;
  height: 95%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
  /* display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr ;
  grid-gap: 15px; */
}
.mod3 .mod3-list li {
  width: 30%;
  height: 30%;
}
.mod3 .mod3-list img {
  width: 100%;
  height: 100%;
}
/* .mod3 .chart4{
    width: 50%;
    height: 50%;
    margin: 0 auto;
} */
.mod .detail {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  grid-template-rows: 1fr;
}
.mod4-list {
  margin-top: 5%;
  margin-bottom: 5%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-gap: 30px;
}
.mod4-list li {
  width: 100%;
  height: 100%;
  background-color: #08172e;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 0 40px;
  font-size: 32px;
  color: #fff;
}
.mod4-list .txt {
  width: 100%;
  height: 80%;
}
.mod4-list .txt img {
  margin-top: 2%;
  width: 100%;
  height: 80%;
}

.mod6-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 30px;
}
.mod6-list .item {
  position: relative;
  overflow: hidden;
}
.mod6-list .title {
  font-size: 30px;
  color: #fff;
  text-align: center;
  line-height: 34px;
  padding: 15px 10px;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  background-color: #0a1f3e;
}

.cen-list {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  flex-wrap: wrap;
  padding: 0 7%;
}
.cen-list li {
  text-align: center;
  width: 30%;
  margin-top: 20px;
}
.cen-list .title {
  font-size: 44px;
  color: #fff;
  font-family: "RuiZiZhenYan";
  height: 70px;
  line-height: 70px;
  background: url(../assets/images/jt_01.png) no-repeat center;
  background-size: 100% 100%;
}
.cen-list .title em {
  font-size: 30px;
  font-family: "RuiZiZhenYan";
}
.cen-list .txt {
  font-size: 52px;
  color: #45f5ff;
  font-family: "PangMenZhengDao";
  margin-top: 10px;
}

.cen-map {
  position: absolute;
  width: calc(100% + 300px);
  height: 100%;
  left: 0;
  top: 0;
  z-index: 1;
  margin-left: -150px;
}
.cen-map .pic {
  width: 100%;
  height: 100%;
  margin-left: 2%;
  max-width: initial;
}
.cen-map .point {
  position: absolute;
}
.cen-map .point .tag {
  font-size: 26px;
  color: #fff;
  display: inline-block;
  vertical-align: middle;
  padding: 0 30px;
  border-radius: 8px;
  line-height: 55px;
  border: 1px solid #45f5ff;
  background-color: #082658;
}
.cen-map .point .icon {
  width: 100px;
  display: block;
  margin: 0 auto;
  margin-top: 20px;
}

.cen-map .item:nth-child(2) {
  /* opacity: 1; */
  top: 57%;
  left: 15%;
}
.cen-map .item:nth-child(3) {
  top: 55%;
  left: 6%;
}
.cen-map .item:nth-child(4) {
  top: 47%;
  left: 23%;
}
.cen-map .item:nth-child(5) {
  top: 45%;
  left: 23%;
}
.cen-map .item:nth-child(6) {
  top: 40%;
  left: 15%;
}
.cen-map .item:nth-child(7) {
  top: 35%;
  left: 36%;
}
.cen-map .item:nth-child(8) {
  top: 51%;
  left: 33%;
}
.cen-map .item:nth-child(9) {
  top: 71%;
  left: 35%;
}
.cen-map .item:nth-child(10) {
  top: 48%;
  left: 50%;
}
.cen-map .item:nth-child(11) {
  top: 35%;
  left: 36%;
}
.cen-map .item:nth-child(12) {
  top: 37%;
  left: 46%;
}
.cen-map .item:nth-child(13) {
  top: 35%;
  left: 45%;
}

.cen-map .item {
  opacity: 0;
  position: relative;
  width: 750px;
  /* border-radius: 8px; */
  /* background-color: rgba(8, 52, 120, 0.7); */
  background: url(../assets/images/zhgl_center.png) no-repeat center;
  background-size: 100% 100%;
  /* padding: 30px; */
  position: absolute;
  z-index: 50;
}
.cen-map .item .top {
  width: 750px;
}
.cen-map .item .top-container {
  margin-top: 80px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}
.cen-map .item .top-container .line {
  width: 350px;
  height: 1px;
}
.cen-map .item .hdpic {
  background: url(../assets/images/top_center.png) no-repeat center;
  background-size: 100% 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 750px;
  height: 80px;
}
.cen-map .item .hdpic .title {
  margin-left: 20px;
  line-height: 80px;
  font-size: 28px;
  font-family: YouSheBiaoTiHei-Bold, YouSheBiaoTiHei;
  font-weight: 400;
  background: linear-gradient(180deg, #ffffff 45%, #23d9e4 100%);
  -webkit-background-clip: text;
  color: transparent;
}
.cen-map .item .info {
  display: flex;
  flex-wrap: wrap;
  margin-left: 20px;
  align-items: center;
}
.cen-map .item .name {
  font-size: 28px;
  font-family: YouSheBiaoTiHei-Bold, YouSheBiaoTiHei;
  font-weight: 400;
  background: linear-gradient(180deg, #ffffff 45%, #23d9e4 100%);
  -webkit-background-clip: text;
  color: transparent;
}
.cen-map .item .type {
  font-size: 28px;
  font-family: YouSheBiaoTiHei-Bold, YouSheBiaoTiHei;
  font-weight: 400;
  background: linear-gradient(180deg, #ffffff 45%, #6c9698 100%);
  -webkit-background-clip: text;
  color: transparent;
}
.cen-map .item .tel {
  /* margin-top: 8px; */
  font-size: 28px;
  font-family: YouSheBiaoTiHei-Bold, YouSheBiaoTiHei;
  font-weight: 400;
  background: linear-gradient(180deg, #ffffff 45%, #23d9e4 100%);
  -webkit-background-clip: text;
  color: transparent;
}
.cen-map .item .bottom_center {
  background: url(../assets/images/bottom_center.png) no-repeat center;
  background-size: 100% 100%;
  width: 49px;
  height: 26px;
  position: absolute;
  bottom: -22px;
  left: 350px;
}
.cen-map .item .line_center {
  background: url(../assets/images/line_center.png) no-repeat center;
  background-size: 100% 100%;
  width: 563px;
  height: 5px;
  position: absolute;
  bottom: 0px;
  left: 90px;
}
.cen-map .item1 {
  left: 50%;
  top: 40%;
}
</style>
